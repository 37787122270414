<template>
    <div class="background">
        <div class="newsletter" v-if="loginform">
            <h2>Subscribe to Our Newsletter</h2> 
            <p>Get The Latest Blogs From Intrioxa</p>
            <input type="text" required placeholder="First Name"  v-model="firstName" />
            <input type="text" required placeholder="Email Address" v-model="emailAddress" />
            <button v-if="!isSub" v-on:click="submit" style="display: block; margin: auto;">Submit</button>
            <button v-if="isSub"  style="display: block; margin: auto; background-color: seagreen;">Thank You</button>
        </div>
    </div>      
</template>
<script>
import db from '../firebase/firebaseInit'
export default {
    data: function() {
        return {
            firstName: '',
            emailAddress:   '',
            isSub: false,
            loginform: true
            }
    },
    methods:{
        submit: function() {
            db.collection("EmailAddresses").add({
                firstName: this.firstName,
                emailAddress: this.emailAddress
            })
            this.isSub = true;
            this.firstName = '';
            this.emailAddress = '';

            setTimeout(() => {this.loginform = false;}
            , 1000);
        }
    }
}
</script>
<style lang="scss" scoped>

.newsletter{
    padding: 35px;
    display: block;
    margin: auto;
    flex-direction: column;
    // border: 1px solid #488eeb;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;
    
}
  h2{
      font-family: "Gill Sans", sans-serif;
      text-align: center;
      color: #303030;
      font-weight: 300;
      margin-bottom: 5px;
      letter-spacing: 5px;
  }
  p{
      font-family: "Gill Sans", sans-serif;
      text-align: center;
      margin-bottom: 25px;
      color: #303030;
  }
.newsletter input{
    width: 20%;
    display: block;
    margin: auto;
    // background: #fafafa00;
    padding: 10px;
    // color: #303030;
    border: 2px solid #303030;
    border-radius: 10px;
    margin-bottom: 17px;
}
//  .newsletter h2{
//      font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
//  }
 @media only screen and (max-width: 600px) {
    .newsletter input{
        width: 100%;
    }
 }
</style>