import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyBTVC1MB7xn6QONtU6cnyDFHUKhJrq_Q3I",
  authDomain: "intrioxa-blogs.firebaseapp.com",
  projectId: "intrioxa-blogs",
  storageBucket: "intrioxa-blogs.appspot.com",
  messagingSenderId: "482105765039",
  appId: "1:482105765039:web:a295b8760afb7eee3c385a",
  measurementId: "G-XM4NC17H8E"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();
